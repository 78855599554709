<template>
  <div class="container_box" @scroll="scrollBox($event)">
    <TopBar :pageTitle="'热门活动'"/>
    <div class="content_warp">
      <div class="banner_cont">
        <p class="img"><a href="https://www.wenjuan.com/s/I3Ajye5/#" target="_blank" rel="noopener noreferrer"><img src="@/assets/m/activity_img/m_banner1.png" alt="" /></a></p>
      </div>
      <div class="btn_list flex">
        <p class="title">方向</p>
       <el-select v-model="activeIndex">
            <el-option v-for="item in categoryList" :label="item.title" :value="item.id" :key="item.id">

            </el-option>
        </el-select>
        <!-- <ul>
          <li  @click="changeBtn('')" :class="activeIndex==''?'active':''">全部</li>
          <li v-for="(item) in categoryList" @click="changeBtn(item.id)" :class="activeIndex==item.id?'active':''" :key="item.id">{{item.title}}</li>
        </ul> -->
      </div>
      <div class="list_box">
        <ul>
          <li v-for="item in activeList" :key="item.id" @click="goDetail(item.id)">
            <p class="img">
              <img v-if="item.image" :src="item.image" alt="">
              <!-- <img v-else src="@/assets/pc/active/img.png" alt="" /> -->
            </p>
            <div class="item_cont">
              <p class="title overflow_y">
                {{item.title}}
              </p>
              <p class="info overflow_e">
                {{item.desc}}
              </p>
              <div class="icon flex">
                <p class="flex">
                  <img src="@/assets/m/activity_img/icon1.png" alt="" />
                  <span>{{item.way_text}}</span>
                </p>
                <!-- <p  class="flex">
                  <img src="@/assets/m/activity_img/icon2.png" alt="" />
                  <span></span>
                </p> -->
              </div>
            </div>
          </li>
          

        </ul>
      </div>
       <div class="more">{{moreText}}</div>
    </div>
    <!-- <Empty v-if="activeList.length <= 0" />

      <page-nation
      class="page_nation"
        v-if="num > 0"
        :num="num"
        :limit="limit"
        @inputNum='inputNum'
        @getNew="getNew"
      ></page-nation> -->
  </div>
</template>
<script>
// import pageNation from "../components/page_nation.vue";
// import Empty from "../components/common/empty.vue";

// import {activityCategory, activityList} from '@/API/api'
import TopBar from "@/m/common/topBar.vue";

export default { 
  name:'active_box',
  components: {
    // pageNation,
    // Empty,
    TopBar
  },
  data() {
    return {
        // 分页信息
      num: 0, //数据总个数
      limit: 10, //一页显示几个
      nowPage: 1, //当前页码
      activeList:[],
      activeIndex:'',
      categoryList:[{title:'全部',id:''}],
      more: true,
      moreText: '加载中...',
      urlHead:'http',
    };
  },
  mounted(){
    let urlHttp = window.location.href
    this.urlHead = 'http'
    if(urlHttp.includes('https')){
       this.urlHead = 'https'
    }
    this.getCategory()
    this.getList()
  },
  methods: {
    getCategory(){
      this.$axios.get(this.urlHead+`://newkskapi.kskstudy.com/om/v1/api/activity/category`).then(res=>{
         this.categoryList =[{title:'全部',id:''}]
         
        this.categoryList.push(...res.data)
      })
    },
    getList(){
       this.$axios.get(this.urlHead+`://newkskapi.kskstudy.com/om/v1/api/activity/list?category_id=`+this.activeIndex+`&per_page=`+this.limit+`&page=`+this.nowPage).then(res=>{
            this.activeList.push(...res.data.data)
          this.num = res.data.total
          this.more = res.data.total>this.activeList.length ? true : false;
            if(!this.more){
            this.moreText = '我是有底线的'
          }
      })
    },
    goDetail(id){
      this.$router.push('/activity/detail?id='+id)
    },
    changeBtn(index){
        this.activeIndex = index
        this.nowPage=1
        this.getList()
    },

    //上拉加载
    scrollBox(e) {
      if(e.target.scrollHeight-e.target.clientHeight == e.target.scrollTop && this.more){
        this.nowPage ++;
        this.getList();
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.container_box{
    background: #F9F9F9;
    padding-bottom: 30px;
   .banner_cont{
    width: 690px;
    margin: 50px auto 0;
    img{
        width: 690px;
    }
   }
   .btn_list{
    margin-top: 50px;
    margin-left: 30px;
    p.title{
        font-size: 26px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-right: 20px;
    }
   }
   .list_box{
    width: 690px;
    margin: 50px auto 0;
    li{
        // width: 100%;
        background: #fff;
        min-height: 156px;
        background: #FFFFFF;
        border-radius: 10px ;
        margin-bottom: 30px;
        display: flex;
        padding: 20px ;
        p.img{
            width: 240px;
            height: 136px;
            border-radius: 10;
            img{
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
        div.item_cont{
            margin-left: 20px;
            flex: 1;
            p.title{
                font-size: 28px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                // height: 74px;
            }
            p.info{
              font-size: 24px;
              color: #666;
              margin-top: 10px;
            }
            div.icon{
                margin-top: 20px;
                img{
                    width: 30px;
                    height: 30px;
                }
                span{
                    font-size: 20px;
                    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                }
            }
        }
    }
   }
}
.more{
  padding: 60px 0 30px;
  text-align: center;
  font-size: 28px;
  color: #999;
}
</style>